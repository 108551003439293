import Share from "./App/Share";
import './Header.scss'

function Content() {

  return (
    <div className="app-header">
      <div className="brand">
        <div className="brand-content">
          <img src="./logo.svg" alt="logo" width="35" height="35" />
          <h1 className="title">たかまつマイセーフティマップ</h1>
        </div>
      </div>
      <div className="drawer">
        <input type="checkbox" id="drawer-check" className="drawer-hidden" />
        <label htmlFor="drawer-check" className="drawer-open"><span></span></label>
        <nav className="drawer-content">
          <div className="drawer-title">
            <p>メニュー</p>
          </div>
          <div className="drawer-body">
            <p className="about-app">「たかまつマイセーフティマップ」は、自宅、学校、職場などをタップすると、洪水、高潮、土砂災害のリスクが分かる高松市の防災アプリ。以下のリンク先も参考に、災害に備えましょう。</p>
            <ul className="drawer-list">
              <li className="drawer-item">
                <a href="https://www.city.takamatsu.kagawa.jp/kurashi/kurashi/shobo/bosai_map/takamatsu_map/index.html" target="_blank" rel="noreferrer">高松市防災マップ</a>
              </li>
              <li className="drawer-item">
                <a href="https://www.city.takamatsu.kagawa.jp/kurashi/kurashi/shobo/bosai_map/takamatsu_map/kouzui_sinsui/hinanjouhounopoint.html" target="_blank" rel="noreferrer">避難情報のポイント</a>
              </li>
            </ul>
            <div className="divider"></div>
            <a href="https://www.city.takamatsu.kagawa.jp/" target="_blank" rel="noreferrer">
              <div className="drawer-body-brand">
              <img src="./logo.svg" alt="logo" width="40" height="40" />
                <h1 className="title">高松市</h1>
              </div>
            </a>
            <Share />
            <div className="release-date">2022年10月31日初版公開</div>
            <details>
              <summary>個人情報の取り扱いについて</summary>
              <p>当サイトでは、個人情報の収集や利用又は提供、管理について「高松市個人情報保護条例」に基づき、次のとおり適切に取り扱います。</p>
              <h2>個人情報とは</h2>
              <p>個人に関する情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と照合することができ、それにより特定の個人を識別することができることとなるものを含む。）をいいます。</p>
              <h2>収集する情報の範囲</h2>
              <p>当サイトで収集する情報の範囲は、次のとおりとします。</p>
              <ol>
                <li>当該サイトでは、インターネットドメイン名、IPアドレス、サイト内検索のクエリ情報、その他当該サイト閲覧に係る情報を自動的に取得します。なお、Cookie（サーバ側で利用者を識別するために、サーバから利用者のブラウザに送信され、利用者のコンピュータに蓄積させる情報）は、ユーザビリティーの向上を目的とする内容に限定するものであり、個人情報は一切含みません。</li>
                <li>サイトの利用状況を把握するために<a href="https://developers.google.com/analytics/" target="_blank" rel="noopener noreferrer">Google Analytics（外部サイトへリンク）</a>を利用しています。Google Analyticsは、Cookieを利用して利用者の情報を収集します。詳細については、<a href="https://policies.google.com/technologies/partner-sites?hl=ja" target="_blank" rel="noopener noreferrer">「Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用（外部サイトへリンク）」</a>をご覧ください。</li>
              </ol>
            </details>
          </div>
        </nav>
      </div>
      <div id="guide-panel">
        <input type="checkbox" id="guide-panel-check" className="guide-panel-hidden" defaultChecked />
        <div className="guide-panel-content">
          <p>自分の家、職場、子どもたちの学校をタップしてみよう！</p>
          <label htmlFor="guide-panel-check" className="guide-panel-open guide-panel-button">
            <i className="arrow down"></i>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Content;
